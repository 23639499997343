.count-circle {
  animation: opacityChange 1s;
}

@keyframes opacityChange {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}