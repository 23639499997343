.banner {
  min-width: 1280px;
  margin: 0px auto;
  height: 325px;
  background-size: cover!important;
  :global {
    .banner {
      &-box {
        min-width: 1280px;
        max-width: 1360px;
        margin: 0px auto;
        overflow: hidden;
      }

      &-title {
        margin-top: 112px;
        font-size: 42px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #252B3A;
        letter-spacing: 1px;
      }
      &-dec {
        margin-top: 15px;
        height: 20px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #303033;
      }
    }
  }
}