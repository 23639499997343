.quoteContainer {
  padding-left: 12px;
  margin: 8px 0;
  position: relative;
  color: #646a73;
}
.quoteContainer:before {
  width: 2px;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2px;
  background: rgb(187, 191, 196);
  border-radius: 1px;
  -webkit-transform-origin: top;
  transform-origin: top;
}