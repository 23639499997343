.container {
  padding: 12px 10px;

  .title {
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: bold;
    font-size: 16px;
    color: #1d2129;
    line-height: 28px;
  }

  .menuList {
    margin: 17px 10px 9px 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 48px;

    .menuItem {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      font-style: normal;
      text-transform: none;
      cursor: pointer;
    }

    .menuItem.menuActive {
      color: #d94b4b;
    }
  }

  .verify {
    width: 42px;
    height: 32px;
    background-color: #db4a48;
    border-radius: 2px 2px 2px 2px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
    line-height: 32px;
    font-style: normal;
    text-transform: none;
    padding: 0;
    text-align: center;
    cursor: pointer;
    &:active {
      background-color: #b3151d;
    }
  }
  .content {
    margin: 0 10px 20px 10px;
    background-color: white;
    display: flex;

    .contentLeft {
      flex: 1;
      width: 0;
      border-right: 2px solid #e7e7e7;
      padding: 20px;
    }
  }

  .datePicker {
    background-color: #f2f3f5;
    border: 1px solid #f2f3f5;
    border-radius: 2px 2px 2px 2px;
    &:hover {
      border-color: #e64e4c;
    }
  }

  .select {
    background: #f2f3f5;
    border-radius: 2px 2px 2px 2px;

    :global {
      .ant-select-selector {
        border: 1px solid #f2f3f5;
        background-color: #f2f3f5;
        border-radius: 2px 2px 2px 2px;
      }
    }
  }

  .input {
    border: 1px solid #f2f3f5;
    background: #f2f3f5;
    border-radius: 2px 2px 2px 2px;
    &:focus {
      border-color: #e64e4c;
    }
    &:hover {
      border-color: #e64e4c;
    }
  }
  .defaultIcon {
    display: block;
    width: 154px;
    margin: 0 auto;
    padding-top: 184px;
  }

  .tagWrap{
    width: 927px;
    .tagTitle{
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: #303033;
      line-height: 21px;
      position: relative;
      padding-left: 10px;
      margin: 20px 0;
      &::before{
        content: '';
        width: 3px;
        height: 14px;
        background: #D94B4B;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
    .row + .row{
      margin-top: 12px;
    }
    .row{
      display: flex;
      padding: 4px 11px;
      background: #F7F9FA;
      align-items: center;
      cursor: pointer;
      position: relative;
      .editButton{
        width: 75px;
        height: 22px;
        background: #DB4A48;
        border-radius: 4px 4px 4px 4px;
        font-size: 13px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        display: none;
      }
      &.active{
        background-color: #FFF2F1;
      }
      &:hover{
        background-color: #FFF2F1;
        .editButton{
          display: flex;
        }
      }
      img{
        vertical-align: text-top;
        margin-right: 3px;
      }
      
      .name{
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .detailItem + .detailItem {
        margin-top: 5px;
      }
      .detailItem{
        display: flex;
        margin-left: 10px;
        justify-content: space-between;
     
        .word{
          width: 191px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          align-items: center;
          margin: auto 0;
        }
        .hitField{
          width: 191px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: auto 0;
        }
        .hitPath{
          width: 191px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: auto 0;
        }
      }
    }
  }
}
