.visualNavigation {

  :global {
    .row-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 1280px;
      max-width: 1360px;
      padding-top: 56px;
      padding-bottom: 60px;
      margin: 0 auto;

      .row {
        width: 100%;
        margin-bottom: 80px;

        .row-tit {
          font-size: 18px;
          font-weight: bold;
          color: #303033;
          margin-bottom: 30px;
        }

        .row-content {
          display: flex;
          justify-content: space-between;

          .item-box {
            box-sizing: border-box;
            width: 248px;
            height: 132px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #EBEBEB;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all .2s;
            position: relative;

            &:hover {
              //height: 124px;
              box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.12);
              border: 4px solid #FFE8E8;
            }

            .label-box {
              color: #303033;
            }

            a {
              display: inline-block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

  }
}