.image-block {
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  word-break: break-word;
  line-height: 1.8;
  .image {
    max-width: 100%;
    margin: 0 auto;
    display: inline-block;
    cursor: pointer;
  }
}
.imagePreviewer {
  min-width: 100px;
  min-height: 100px;
  background: #ccc;
}