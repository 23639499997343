.listBlock {
  padding-left: 1.5em;
  margin: 8px 0;
  list-style: none;
  position: relative;
}
.olBlock {
  &:before{
    content: counter(ordered, lower-roman) ".";
    color: rgb(51 112 255);
    counter-increment: ordered;
    position: absolute;
    text-align: right;
    transform: translateX(-1.5em);
   }
}
.ulBlock {
  &:before{
    content: "•";
    font-size: 16px;
    font-weight: bolder;
    color: rgb(51 112 255);
    position: absolute;
    text-align: right;
    transform: translateX(-1.5em) scale(1.375);
   }
}
.childList {
  list-style: none;
}