.subjectWrapperBox {
  background: linear-gradient(360deg, #FFFFFF 0%, #F8FAFC 100%);
  padding-top: 80px;

  :global {
    .big-title {
      margin-bottom: 80px;
      text-align: center;
      height: 36px;
      font-size: 36px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #060605;
    }

    .info-box {
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(45, 45, 45, 1);
      font-size: 16px;
      margin-bottom: 100px;
      .icon-box {
        img {
          min-width: 640px;
          max-width: 672px;
        }
      }

      .info-top-tit-box {
        min-width: 640px;
        max-width: 672px;
        margin-left: 32px;

        .tit-item {
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 500;
          color: #6E7176;
          line-height: 48px;
        }

        .blue-tit {
          min-width: 640px;
          max-width: 672px;
          height: 20px;
          line-height: 20px;
          margin-bottom: 38px;
          font-size: 20px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 600;
          color: #060605;
        }

        .blue-ico {
          color: rgba(119, 190, 203, 1);
          margin-right: 20px;
        }
      }

      .info-bottom-tit-box {
        justify-content: center;
        display: flex;
        flex-direction: column;
        min-width: 640px;
        max-width: 672px;
        margin-right: 32px;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #6E7176;
        line-height: 38px;
      }
      .info-bottom-tit-nav{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 28px;
        > span{
          width: 306px;
          height: 48px;
          background: #FAFBFC;
          box-shadow: 0px 2px 4px 0px rgba(231,237,243,0.5);
          border-radius: 8px;
          border: 1px solid #FFFFFF;
          text-align: center;
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          //font-weight: 400;
          color: #70737E;
          margin-top: 17px;
        }
        > span:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          height: 100%;
        }
      }
    }

  }


}