.container{
    padding: 12px 10px;
    .breadcrumb{
        display: flex;
        line-height: 28px;
        color: #4E5969;
        font-size: 14px;
        margin-bottom: 17px;
        .title{
            font-size: 16px;
            color: #1D2129;
            font-weight: bold;
            margin-right: 14px;
        }
    }
    .content{
        display: flex;
        .emptyData{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        :global{
            .hidden{
                width: 0;
                height: 0;
                padding: 0;
                margin: 0;
            }
        }
    }
}

.leftWrap{
    position: relative;
    .float{
        width: 63px;
        height: 56px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
        border-radius: 0px 33px 33px 0px;
        position: fixed;
        left: 0;
        top: 210px;
        z-index: 1;
        cursor: pointer;
        &.tagList{
            top: 280px;
        }
        img{
            width: 26px;
            margin-top: 15px;
            margin-left: 12px;
        }
    }
}
.leftCondition{
    width: 280px;
    background: #fff;
    padding: 10px 5px 10px 10px;
    border-radius: 4px;
    height: calc(100vh - 122px);
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    margin-right: 20px;
    z-index: 5;
    position: relative;
    .scrollWrap{
        width: 100%;
        height: calc(100% - 70px);
        overflow-y: auto;
    }
    .title{
        font-size: 16px;
        font-weight: bold;
        line-height: 38px;
        border-bottom: 1px solid #F6F6F6;
        display: flex;
        justify-content: space-between;
        .shrink{
            cursor: pointer;
        }
    }
    .box{
        padding: 10px;
        background: #F2F3F5;
        border-radius: 4px;
        margin: 10px 0;
        &.pb0{
            padding-bottom: 0;
        }
        :global{
            .ant-row{
                width: 100%;
            }
            .ant-col{
                margin-bottom: 10px;
            }
            label{
                font-size: 12px;
            }
            // .ant-checkbox-inner{
            //     width: 14px;
            //     height: 14px;
            // }
            .ant-checkbox+span{
                padding-inline-start:2px;
                padding-inline-end:2px;
            }
        }
        .top{
            margin-bottom: 14px;
        }
        .conditionTitle{
            display: flex;
            justify-content: space-between;
            color: #4E5969;
            font-size: 12px;
            line-height: 22px;
            margin-bottom: 5px;
        }
        .input{
            line-height: 20px;
        }
    }
    .buttonWrap{
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 10px;
        .handleButton{
            flex: 1;
        }
        .handleButton + .handleButton {
            margin-left: 15px;
        }
    }
}

.rightList{
    background: #ffffff;
    width: calc(100% - 300px);
    padding: 15px 20px;
    transition: width 0.3s ease-in-out;
    height: calc(100vh - 122px);
    overflow-y: auto;
    &.fullWidth{
        width: 100%;
    }
    .empty{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: gray;
        img{
            width: 300px;
            margin-top: 10%;
        }
    }
    .pagination{
        float: right;
    }
    .noHit{
        color: #909099;
    }
    .toolBar{
        display: flex;
        justify-content: space-between;
    }
    .leftData{
        display: flex;
        flex: 1;
        justify-content:flex-start;
        .statistics{
            margin-left: 10px;
        }
        .item + .item {
            margin-left:20px;
        }
        .rate{
            color: #DB4A48;
        }
    }
    .rightBar{
        display: flex;
        flex: 1;
        justify-content: flex-end;
        margin-bottom: 4px;
        :global{
            .ant-select-selector{
                border: none;
                background: #F2F3F5;
            }
        }
        .operator + .operator {
            margin-left: 8px;
        }
    }
    .listItem{
        padding: 20px 0px 20px 30px;
        border-top: 1px solid #E7E7E7;
        display: flex;
        position: relative;
        .itemCheck{
            position: absolute;
            top: 20px;
            left: 0;
        }
        .listInfo{
            width: 100%;
            &.hasimg{
                width: calc(100% - 176px);
            }
        }
        .image{
            width:166px;
            height: 166px;
            margin-right: 10px;
            flex-shrink: 0;
            border-radius: 6px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .top{
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            .tag{
                padding: 2px 10px;
                background: #FFF0EC;
                border-radius: 4px;
                font-size: 12px;
                color: #FC8F66;
                line-height: 14px;
                margin-right: 20px;
                flex-shrink: 0;
                &.positive{
                    background: #E8FFEA;
                    color: #00B42A;
                }
                &.negative{
                    background: #E8F7FF;
                    color: #3491FA;
                }
            }
            .title{
                font-size: 16px;
                color: #000000;
                line-height: 22px;
                margin-right: 20px;
                font-weight: bold;
                margin-bottom: 0;
                cursor: pointer;
            }
        }
        .artical{
            font-size: 12px;
            color: #000000;
            line-height: 22px;
            height: 44px;
            margin-bottom: 0;
        }
        .text{
            margin: 5px 0;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
        .info{
            margin: 8px 0;
            font-size: 12px;
            color: #909099;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .infoLeft{
                display: flex;
                div + div{
                    margin-left: 20px;
                }
            }
            .infoRight{
                width: 80px;
                display: flex;
                justify-content: space-between;
                .button{
                    width: 30px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 2px;
                    cursor: pointer;
                    .icon{
                        font-size: 14px;
                    }
                    &.close{
                        background: #FFECE8;
                        .icon{
                            color: #F53F3F;
                        }
                        &.checked{
                            background: #F53F3F;
                            .icon{
                                color: #ffffff;
                            } 
                        }
                        &.gray{
                            background: #F2F3F5;
                            .icon{
                                color: #666666;
                            } 
                        }
                    }
                    &.pass{
                        background:#E8FFEA ;
                        .icon{
                            color: #00B42A;
                        }
                        &.checked{
                            background: #00B42A;
                            .icon{
                                color: #ffffff;
                            } 
                        }
                        &.gray{
                            background: #F2F3F5;
                            .icon{
                                color: #666666;
                            } 
                        }
                    }
                }
            }    
        }
        .dataInfo{
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #F2F3F5;
            border-radius: 4px;
            font-size: 12px;
            color: #666666;
            font-weight: bold;
            padding: 0 10px;
            div{
                width: 30%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}



.modal{
    background-color: transparent;
    :global{
        .ant-modal-content{
            background-color: transparent;
            box-shadow: none;
        }
        .ant-modal-close{
            top: 30px;
            inset-inline-end: 32px;
        }
        
    }
    .detailContent{
        display: flex;
        justify-content: space-between;
        .left{
            width: 854px;
            background: #ffffff;
            padding: 40px;
            border-radius: 12px;
            flex-shrink: 0;
            .buttonWrap {
                display: flex;
                justify-content: space-between;
                width: 85%;
            }
            .title{
                font-size: 16px;
                color: #000000;
                line-height: 22px;
                font-weight:bold;
                margin: 20px 0;
            }
            .videoInfo{
                font-size: 12px;
                color: #909099;
                line-height: 14px;
                .top{
                    display: flex;
                    align-items: center;
                    div+div{
                        margin-left: 20px;
                    }
                }
                .bottom{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                }
            }
            .content{
                font-size: 12px;
                line-height: 22px;
                color: #000000;
                margin-top: 20px;
            }
            .video{
                height: 300px;
                display: block;
                margin: 0 auto;
            }
            .video + .video{
                margin-top: 10px;
            }
            .image{
                margin-top: 10px;
                width: 200px;
                height: 200px;
            }
            .image + .image{
                margin-left: 5px;
            }
        }
        .right{
            width: 554px;
            background: #ffffff;
            padding: 40px;
            border-radius: 12px;
            flex-shrink: 0;
            .title{
                font-size: 15px;
                color: #303033;
                line-height: 18px;
                display: flex;
                .tag{
                    padding: 2px 10px;
                    border-radius: 4px;
                    background: #FFF0EC;
                    color: #FC8F66;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 10px;
                }
            }
            .identifyWrap{
                border-top: 1px solid #E7E7E7;
                margin-top: 20px;
                padding: 20px 0;
                .identifyBox + .identifyBox{
                    margin-top: 20px;
                }
                .identifyBox{
                    padding-left: 48px;
                    position: relative;
                    font-size: 14px;
                    line-height: 22px;
                    color: #000000;
                    .identifyTitle{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 43px;
                        height: 20px;
                        background: #F2F3F5;
                        border-radius: 2px;
                        font-size: 12px;
                        color: #1D2129;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}




.tagListWrap{
    width: 280px;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
    height: calc(100vh - 122px);
    overflow-x: hidden;
    transition: width 0.3s ease-in-out;
    overflow-y: auto;
    margin-right: 20px;
    z-index: 5;
    position: relative;
    .treeTitle{
        font-weight: bold;
        color: #1D2129;
        line-height: 28px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .shrink{
            cursor: pointer;
        }
    }
    .searchInput{
        margin: 10px 0;
        border: none;
        background: #F2F3F5;
        &:focus{
            box-shadow: none !important;
        }
        :global{
            input{
                background: #F2F3F5;
            }
        }
    }
    .treeWrap{
        height: calc(100% - 85px);
        overflow-y: auto;
    }
    .treeItem{
        display: flex;
        justify-content: space-between;
        .folder{
            width: 14px;
            height: 12px;
            margin-right: 10px;
        }
        .tagIcon{
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #F53F3F;
            position: relative;
            top: -2px;
            left: -8px;
            &.enable{
                background: rgb(0,180,42);
            }
            &.disabled{
                background: rgb(201,205,212);
            }
        }
    }
}


.editModal{
    .addArea{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        color: #86909C;
        font-size: 14px;
        line-height: 22px;
        .addButton{
            cursor: pointer;
            position: relative;
            &::before{
                content: '';
                width: 219px;
                height: 1px;
                background: #E5E6EB;
                position: absolute;
                left: -250px;
                top: 10px;
            }
            &::after{
                content: '';
                width: 219px;
                height: 1px;
                background: #E5E6EB;
                position: absolute;
                left: 110px;
                top: 10px;
            }
        }
        .addIcon{
            margin-right: 9px;
        }
    }
    .filterArea{
        margin-top: 10px;
    }
    .modalTitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .relationTitle{
        font-size: 14px;
        font-weight: normal;
        margin-right: 10px;
    }
    :global{
        .ant-modal-body{
            height: calc(100vh - 250px);
            overflow-y: auto;
        }
    }
}

.matchArea{
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    .title{
        font-size: 16px;
        color: #1D2129;
        line-height: 28px;
        font-weight: bold;
    }
}

.hitWrap{
    margin-top: 20px;
    .hitTitle{
        color: #303033;
        font-size: 14px;
        padding-left: 14px;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            width: 3px;
            height: 12px;
            background: #D94B4B;
            left: 0;
            top: 5px;
        }
    }
    .hitItem{
        display: flex;
        justify-content: space-between;
        height: 27px;
        align-items: center;
        background: #F7F9FA;
        margin-top: 10px;
        padding: 0 14px;
    }
    .icon{
        width: 20px;
        height: 20px;
        margin-right: 9px;
    }
    :global{
        .ant-typography{
            margin-bottom: 0;
        }
    }
    .word{
        display: flex;
        align-items: center;
        width: 336px;
        margin-right: 21px;
        :global{
            .ant-typography{
                width: 207px;
            } 
        }
    }
    .hitField{
        display: flex;
        align-items: center;
        width: 135px;
        margin-right: 21px;
        :global{
            .ant-typography{
                width: 84px;
            } 
        }
    }
    .hitPath{
        display: flex;
        align-items: center;
        width:361px;
        :global{
            .ant-typography{
                width: 342px;
            } 
        }
    }
}

.platformIcon{
    width: 16px;
    height: 16px;
    vertical-align: bottom;
    margin-right:5px;
}

:global{
    ::-webkit-scrollbar {
        width: 5px; /* 设置滚动条宽度 */
      }
    .match{
        color: #DB4A48;
    }
    .exclude{
        color: #3686FF;
    }
}

