.asideLayout {
  display: flex;
  height: calc(100vh - 60px);
  .aside {
    //width: 268px;
    height: calc(100vh - 60px);
    overflow-x: scroll;
  }
  .rightContainer{
    width: calc(100vw - 280px);

    flex-grow: 1;
  }

  .rightContent {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #FFFFFF;
  }
  //.docContext {
  //  height: 100%;
  //  overflow-y: scroll;
  //  overflow-x: hidden;
  //  background: #FFFFFF;
  //}
  .loadingMessageContainer {
    font-size: 32px;
    color: #666;
    text-align: center;
    padding: 200px 100px;
  }
}
