.functionsWrapperBox {
  background: #fff;
  :global {
    .row {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 1280px;
      height: 500px;
      padding-top: 80px;
      margin: 0px auto;
      background-image: url("../img/bg.jpg");
      background-size: cover;
      background-position: center;
      .row-tit {
        height: 36px;
        line-height: 36px;
        font-size: 36px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #060605;
      }

      .row-content {
        display: flex;
        margin-top: 124px;
        width: 1260px;
        justify-content: space-between;

        .item-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          .img-box {
            width: 82px;
            height: 82px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .label-name {
            margin-top: 24px;
            height: 16px;
            font-size: 16px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            color: #060605;
          }

          .blue-ico {
            opacity: 0;
            position: relative;
            bottom: -6px;
            color: rgba(60, 27, 134, 1);
            font-size: 20px;
          }
        }

        .item-box.checked {
          .blue-ico {
            opacity: 1;
          }

          .label-name {
            font-weight: bold;
            color: rgba(60, 27, 134, 1);
          }
        }
      }

    }

    .component-box {
      min-height: 470px;
      min-width: 1280px;
      background-image: url("../img/box-bg.png");
      background-size: cover;
      background-position: center;
    }
  }

}