.importDataTrackingDetailWrap {
  :global {

    .table_wrap {
      max-width: 1440px;
      margin: 30px auto;

      .green {
        color: #0ac50a;
        font-weight: bolder;
      }

      .dataStr-box {
        word-break: break-all;
      }

      .blod {
        font-weight: bold;
      }
    }
  }
}