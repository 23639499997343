.docContainer {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
}
.docContext {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #FFFFFF;
}
.loadingMessageContainer {
  text-align: center;
  padding: 200px 100px;
  p {
    font-size: 32px;
    color: #666;
    text-align: center;
    transform: translateX(10px);
    margin-bottom: 15px;
  }
  span {
    color: #888;
    text-align: left;
  }
}
.dot {
  display: inline-block;
  height: 1em; line-height: 1;
  vertical-align: -.25em;
  overflow: hidden;
  &::before {
    display: block;
    content: '...\A..\A.';
    white-space: pre-wrap;
    animation: dot 3s infinite step-start both;
  }
}
@keyframes dot {
  33% { transform: translate3D(0, -2em, 0); }
  66% { transform: translate3D(0, -1em, 0); }
}