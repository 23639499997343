.resourceTracking {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  :global {
    .chartBox-head {
      height: 64px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;
      font-family: PingFang SC-Medium, PingFang SC;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #1D2129;
      }

      .view {
        font-size: 14px;
        color: #165DFF;
        cursor: pointer;
      }
    }
  }
}