.crawlerCollectWrap {
  min-height: calc(100vh - 540px);
  background: #F4F6F8;
  .line{
    width: 100%;
    height: 5px;
    background: #F4F6F8;
  }
  .headBox {
    display: flex;
    justify-content: space-between;
    .title{
      font-size: 16px;
      font-weight: bold;
      color: #1D2129;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .left{
      width: 200px;
      background: #FFFFFF;
      .myMenu{
        height: calc(100vh - 130px);
        :global(.ant-menu-item){
          display: flex;
        }
        :global(img){
          width: 22px;
        }
      }
    }
    .right{
      width: calc(100% - 220px);
      .breadcrumb{
        height: 68px;
        display: flex;
        align-items: center;
      }
      .content{
        background: #ffffff;
        padding: 20px;
        .contentTitle{
          font-size: 16px;
          font-weight: bold;
          color: #1D2129;
        }
        .info{
          margin-top: 5px;
          margin-bottom: 15px;
          color: #86909C;
          line-height: 22px;
          font-size: 14px;
        }
        .block{
          .item{
            color: #4E5969;
            line-height: 22px;
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
        .block + .block{
          margin-top: 15px;
        }
        .buttonWrap{
          display: flex;
          justify-content: flex-end;
          height: 66px;
          align-items: center;
        }
      }
    }
    .centerTit {
      height: 220px;
      background-color: #E8EDF2;
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        width: 1200px;
        font-size: 42px;
        font-weight: bold;
        color: #303033;
        line-height: 55px;
        letter-spacing: 1px;
      }
    }
  }
  .container {
    max-width: 1440px;
    margin: 0 auto;
  }
}