.update {
  :global {
    .title {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      padding: 0 7px;
      background: #46D1A0;
      border-radius: 3px;
      font-size: 12px;
      color: #FFFFFF;
    }

    .resource {
      width: 645px;
      height: 411px;
      background: #F7F7F7;
      border-radius: 6px;
      padding: 11px 9px;
      margin-top: 8px;

      .author {
        img {
          width: 39px;
          height: 39px;
          object-fit: cover;
          margin-right: 13px;
        }

        .authorName {
          font-size: 14px;
          font-weight: bold;
          color: #303033;
        }
      }


      .resourceCover {
        padding-left: 52px;

        .resourceCover-img {
          width: 564px;
          height: 318px;
          position: relative;

          .playBtn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .cover-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .resource-number {
            position: absolute;
            top: 0;
            left: 0;
            height: 43px;
            background: rgba(0, 0, 0, 0.2);
            padding: 0 14px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            color: #FFFFFF;
          }
        }

        .statistics {
          padding-top: 14px;

          >span {
            margin-right: 22px;
          }

          .total {
            font-size: 12px;
            color: #303033;
            margin-left: 2px;
          }
        }


      }
    }
  }
}