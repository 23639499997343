.callout {
  line-height: 28px;
  word-break: break-word;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  border-color: #ffba6b;
  background-color: #feead2;
  font-size: 16px;
  display: flex;
}
.emojiContainer {
  margin-right: 12px;
}
.childrenContainer {
  flex: 1;
}
.emoji {
  font-size: 20px;
}
.text-color {
  &-1 { color: #d83931; }
  &-2 { color: #de7802; }
  &-3 { color: #dc9b04; }
  &-4 { color: #2ea121; }
  &-5 { color: #245bdb; }
  &-6 { color: #6425d0; }
  &-7 { color: #646a73; }
}
.border-color {
  &-1 { border-color: #d83931; }
  &-2 { border-color: #de7802; }
  &-3 { border-color: #dc9b04; }
  &-4 { border-color: #2ea121; }
  &-5 { border-color: #245bdb; }
  &-6 { border-color: #6425d0; }
  &-7 { border-color: #646a73; }
}
.background-color {
  &-1 { background-color: #fdddef; }
  &-2 { background-color: rgb(254 212 164 / 80%); }
  &-3 { background-color: rgb(255 246 122 / 80%); }
  &-4 { background-color: rgb(183 237 177 / 80%); }
  &-5 { background-color: rgb(186 206 253 / 70%); }
  &-6 { background-color: rgb(205 178 250 / 70%); }
  &-7 { background-color: #f2f3f5; }
  &-8 { background-color: #f76964; }
  &-9 { background-color: #ffa53d; }
  &-10 { background-color: #ffe928; }
  &-11 { background-color: #62d256; }
  &-12 { background-color: rgb(78 131 253 / 55%); }
  &-13 { background-color: rgb(147 90 246 / 55%); }
  &-14 { background-color: #bbbfc4; }
  &-15 { background-color: #f2f3f5; }
}
