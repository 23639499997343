.publish {
  :global {
    .title {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      padding: 0 7px;
      background: #F08E02;
      border-radius: 3px;
      font-size: 12px;
      color: #FFFFFF;
    }

    a {
      font-size: 15px;
      font-weight: bold;
      color: #303033;
    }

    .link-box {
      width: 645px;
      height: 60px;
      background: #F7F7F7;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding-left: 9px;
      margin-top: 11px;

      img {
        width: 39px;
        height: 39px;
        margin-right: 13px;
      }

      span {
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }


    }
  }
}