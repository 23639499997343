.download {
  font-size: 14px;

  :global {

    .title {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      padding: 0 7px;
      background: #4BAEFF;
      border-radius: 3px;
      font-size: 12px;
      color: #FFFFFF;
    }

    .resource-wrapper {
      width: 645px;
      height: 130px;
      background: #F7F7F7;
      border-radius: 6px;
      padding-left: 61px;
      display: flex;
      margin-top: 8px;
      align-items: center;

      .resourceCover {
        width: 178px;
        height: 100px;
        object-fit: cover;
      }

      .resource-info {
        margin-left: 10px;

        .row {
          color: #303033;
          font-size: 12px;

          .row-tit {
            color: #66666C;
            margin-right: 6px;
          }
        }
      }
    }
  }
}