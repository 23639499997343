.editModal{
    .addArea{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        color: #86909C;
        font-size: 14px;
        line-height: 22px;
        .addButton{
            cursor: pointer;
            position: relative;
            &::before{
                content: '';
                width: 219px;
                height: 1px;
                background: #E5E6EB;
                position: absolute;
                left: -250px;
                top: 10px;
            }
            &::after{
                content: '';
                width: 219px;
                height: 1px;
                background: #E5E6EB;
                position: absolute;
                left: 110px;
                top: 10px;
            }
        }
        .addIcon{
            margin-right: 9px;
        }
    }
    .filterArea{
        margin-top: 10px;
    }
    .modalTitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .relationTitle{
        font-size: 14px;
        font-weight: normal;
        margin-right: 10px;
    }
    :global{
        .ant-modal-body{
            height: calc(100vh - 250px);
            overflow-y: auto;
        }
    }
}

.matchArea{
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    .title{
        font-size: 16px;
        color: #1D2129;
        line-height: 28px;
        font-weight: bold;
    }
}

@keyframes rotateLoading {
    from{
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotateIcon {
    animation: rotateLoading 2s linear infinite;
}

.mask{
    width: 1087px;
    height: 785px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    background: rgba(255,255,255,0.86);
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}