.loginWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100Vh;
  background-image: url('./../img/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
 

  :global {
    .head {
      position: fixed;
      top: 0;
      left: 0;
      height: 60px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      padding-left: 24px;
      width: 100%;

      img {
        width: 186px;
      }
    }

    .login-box {
      margin-right: 25%;
      width: 400px;
      height: 500px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 0px #E9E5E7;
      border-radius: 10px;
      padding: 0 30px;

      .tit {
        font-size: 23px;
        font-weight: 500;
        color: #1F2329;
        margin-bottom: 45px;
        margin-top: 76px;
      }

      .sub-btn {
        margin-top: 84px;
      }
    }

    .copy-right{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 2%;
      div:first-child {
        background: transparent !important;
        border: none !important;
      }
    }
    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      color: transparent;
    }
  }
}