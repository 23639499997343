.sixSided {
  position: relative;
  width: 655px;
  :global {
    .container {
      font-size: 0; /* 禁用内联块元素之间的空白 */
    }
    .wrap div {
      width: 178px;
      margin: 5px;
      height: 204px;
      display: inline-block;
      font-size: initial;
      background: rgba(197,208,223,0.13);
      clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    }

    .wrap div:nth-child(3n) {
      margin-right: 0px;
    }
    .wrap div:nth-child(6n + 1) {
      margin-left: 99px;
    }
    .wrap div:nth-child(n + 3) {
      margin-top: -51px;
    }
    .container {
      position: absolute;
      top: 0px;
    }
    .container > div {
      width: 172px;
      margin: 8px;
      height: 198px;
      //display: inline-block;
      float: left;
      font-size: initial;
      background: #fff;
      clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    }
    .container > div:nth-child(3n) {
      margin-right: 0px;
    }
    .container > div:nth-child(6n + 1) {
      margin-left: 102px;
    }
    .container > div:nth-child(n + 4) {
      margin-top: -43px;
    }
    .container > div > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 53px;
    }
    .container > div > div img {
      width: 56px;
      height: 56px;
      margin-bottom:11px;
    }
    .container > div > div span {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #303033;
    }
  }
}