.wrapperBox {
  :global {
    .banner {
      min-width: 1280px;
      height: 602px;
      background-image: url('../img/banner.jpg');
      background-size: cover;
      background-position: center;
      overflow: hidden;
      margin: 0px  auto;
      &-box {
        box-sizing: border-box;
        //display: flex;
        //align-items: center;
        width: 100%;
        min-width: 1280px;
        max-width: 1344px;
        margin: 0px  auto;
      }
      &-title {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        height: 84px;
        margin-top: 210px;
        font-size: 60px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D242E;
      }
      &-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 53px;
        margin-top: 34px;
        background: #DA2428;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 25px;
      }
      &-btn::after {
        display: inline-block;
        content: '';
        width: 18px;
        height: 18px;
        margin-left: 10px;
        background-image: url('../img/arrow-r.png');
        background-size: cover;
        background-position: center;
      }
    }
  }
}