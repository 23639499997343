.container{
    padding: 10px;
    .title{
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
        padding-bottom: 10px;
    }
    .filter{
        background: #fff;
        padding: 20px 10px;
    }
    .overview{
        margin-top: 10px;
        background: #fff;
        padding: 20px 10px;
        .subtitle{
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
            border-bottom: 1px solid #e0e0e0;
            padding-bottom: 10px;
        }
        .flexWrap{
            display: flex;
            justify-content: space-between;
        }
    }
    .table{
        margin-top:10px;
    }
}