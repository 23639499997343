.comment {
  :global {
    .title {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      padding: 0 7px;
      background: #FA8383;
      border-radius: 3px;
      font-size: 12px;
      color: #FFFFFF;
    }

    .row {

      width: 645px;
      background: #F7F7F7;
      border-radius: 6px;
      padding: 15px 20px 20px 9px;
      display: flex;
      margin-top: 8px;

      .avatarUrl {
        width: 39px;
        height: 39px;
        object-fit: cover;
        border-radius: 6px;
      }

      .info-box {
        margin-left: 13px;
        flex: 1;

        .author-statistics {
          display: flex;
          justify-content: space-between;

          .authorName {
            font-size: 14px;
            font-weight: bold;
            color: #303033;
          }

          .statistics {
            font-size: 12px;
            color: #303033;

            >span {
              margin-left: 22px;
            }
          }
        }

        .content {
          font-size: 14px;
          color: #3C3C3E;
          line-height: 19px;
        }
      }
    }
  }
}