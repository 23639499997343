.userWrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  :global {

    .login {
      color: #fff;

      .userName {
        color: rgb(48, 48, 51);
        font-weight: 500;
        margin-right: 5px;
      }

      .ant-dropdown-trigger {
        display: flex;
        align-items: center;
      }
    }

    .register {
      width: 120px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background: #00F9F8;
      border-radius: 32px 32px 32px 32px;
      font-size: 16px;
      color: #323233;
      margin-left: 20px;
    }
  }
}

.overlay {
  border-radius: 2px;
  background-color: #fff;

  :global {
    .row {
      border-radius: 2px;
      padding: 4px 8px;
      cursor: pointer;
      color: #666;
      font-size: 12px;
      white-space: nowrap;
      text-align: center;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}