.tableBlock {
  padding-bottom: 2px;
  margin-top: 21.5px;
  margin-bottom: 16px;
  border-collapse: collapse;
}

.cell {
  border: 1px solid #dee0e3;
  min-height: 39px;
  min-width: 50px;
  padding: 8px;
  font-size: 14px;
  vertical-align: top;
}