.page-block {
	max-width: 820px;
	margin: 0 50px;
}
.header-container {
	padding: 20px 0 6px;
	margin-bottom: 16px;
	word-break: break-word;
}
.header {
	min-height: 55.25px;
	font-size: 34px;
	font-weight: 600;
	line-height: 1.625;
	letter-spacing: .02em;
}