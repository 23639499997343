*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  font-family: PingFangSC-Medium, PingFang SC;
  min-width: 1260px;
}
a {
  text-decoration: none;
  outline: none;
  color: #303033;
}

.fn-ml5 {
  margin-left: 5px;
}

.fn-mr5 {
  margin-right: 5px;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background : #eee;
  &:hover {
    background: #e3e3e3;
  }
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background : transparent;
}
