.filterWrap{
    padding: 24px 20px;
    background: #F2F3F5;
    .title{
        font-size: 16px;
        color: #1D2129;
        font-weight: bold;
        line-height: 28px;
    }
    .blockTitle{
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        padding-bottom: 6px;
        font-size: 12px;
        color: #4E5969;
        line-height: 22px;
    }
    .filterArea{
        display: flex;
        .filterItem{
            display: flex;
            align-items: center;
            font-size: 12px;
            justify-content: space-between;
            .filterTitle{
                margin-right: 10px;
                color: #4E5969;
            }
        }
        .filterItem + .filterItem{
            margin-left: 10px;
        }
    }
    .inputArea{
        position: relative;
        .validateExpression{
            position: absolute;
            right:10px;
            bottom:5px;
            font-size: 12px;
            line-height: 20px;
            color: #F77234;
            cursor: pointer;
            &.wrong{
                color: #DB4A48;
            }
            &.correct{
                color: #00B42A;
               
            }
           :global{
            .anticon-reload{
                margin-left: 8px;
            }
           }
        }
    }
    .questionTip{
        margin-left: 5px;
    }
}