.article {
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 20px;

  .articleButton {
    display: block;
    height: 30px;
    padding: 0 10px;
    background: #fafafa;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #e8e8e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #303033;
    line-height: 14px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    cursor: pointer;

    &:active {
      background-color: #f0f0f0;
    }

    div {
      margin-left: 6px;
    }
  }

  .articleTitle {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-top: 20px;
  }

  .platform {
    width: 16px;
    margin-right: 5px;
  }

  .author {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #909099;
    line-height: 14px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: flex;
    align-items: center;
    margin-top: 20px;
    span {
      margin-right: 20px;
    }
  }

  .content {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 10px 0;
  }

  .video {
    display: block;
    width: 612px;
    height: 344px;
    margin: 0 auto;
  }

  .image {
    display: block;
    width: 612px;
    margin: 0 auto;
  }

  .desc {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #979797;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .bottom {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    div {
      margin-left: 10px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #86909c;
      font-style: normal;
      text-transform: none;

      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
  }
}
