.trans {
  :global {
    .title {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      padding: 0 7px;
      background: #A573DE;
      border-radius: 3px;
      font-size: 12px;
      color: #FFFFFF;
    }

    .img-list-wrapper {
      width: 645px;
      height: 240px;
      padding-left: 61px;
      background: #F7F7F7;
      border-radius: 6px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;

      >div {
        width: 178px;
        height: 100px;
        margin-right: 10px;
        margin-bottom: 10px;

        >img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .img-info {
        padding-top: 10px;
        font-size: 12px;
        flex-direction: column;
        display: flex;
        color: #303033;

        .row {
          margin-bottom: 5px;

          .row-tit {
            display: inline-block;
            width: 62px;
            color: #66666C;
          }
        }
      }
    }
  }
}