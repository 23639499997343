.serviceDataWrapper {
  min-width: 1280px;
  max-width: 1360px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
  margin: 0px auto;
  >div {
    margin-bottom: 16px;
    width: 632px;

    &:nth-child(2n-1) {
      margin-right: 16px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .serviceDataWrapper {
    >div {

      width: 622px;

      &:nth-child(2n-1) {
        margin-right: 10px;
      }
    }

  }
}