.heading1, .heading2, .heading3, .heading4, .heading5, .heading6, .heading7, .heading8, .heading9 {
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 8px;
}
.docx-text-block {
  line-height: 26px;
}
.docx-heading1-block {
  font-size: 26px;
}
.docx-heading2-block {
  font-size: 22px;
}
.docx-heading3-block {
  font-size: 18px;
}
.docx-heading4-block {
  font-size: 18px;
}
.docx-heading5-block {
  font-size: 14px;
}