.navigationWrapperBox {
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    .link-list-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1280px;

      .link-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 300px;
        height: 300px;
        position: relative;
        cursor: pointer;

        .item-box {
          //transform: translate(-50%, -50%);
          //width: 100%px;
          display: flex;
          justify-content: center;
          align-items: center;

          .img-box {
            height: 86px;
            width: 86px;

            img {
              width: 100%;
              height: 100%;
            }

          }

        }

        .label-name {
          height: 18px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #060605;
        }

        .item-box:hover  {
          margin-top: -20px;
          width: 106px;
          height: 106px;
          .img-box {
            width: 106px;
            height: 106px;
          }
        }
      }
    }
  }

}