.group{
    background: #F2F3F5;
    border-radius: 4px 4px 4px 4px;
    padding: 20px;
    margin-bottom: 20px;
    .inputArea{
        position: relative;
        .validateExpression{
            position: absolute;
            right:10px;
            bottom:5px;
            font-size: 12px;
            line-height: 20px;
            color: #F77234;
            cursor: pointer;
            &.wrong{
                color: #DB4A48;
            }
            &.correct{
                color: #00B42A;
               
            }
           :global{
                .anticon-reload{
                    margin-left: 8px;
                }
           }
        }
    }
    .groupTop{
        display: flex;
        justify-content: space-between;
        padding: 4px 0;
        border-bottom: 1px solid #E7E7E7;
    }
    .operation{
        button + button {
            margin-left: 8px;
        }
    }
    .blockTitle{
        display: flex;
        justify-content: space-between;
        padding-top:15px;
        padding-bottom: 6px;
        font-size: 12px;
        color: #4E5969;
        line-height: 22px;
        .excludeTitle{
            margin-right: 7px;
        }
        .excludeTitleWrap{
            display: flex;
            align-items: center;
        }
    }
    .filterArea{
        display: flex;
        justify-content: space-between;
        .filterItem{
            display: flex;
            align-items: center;
        }
        .filterTitle{
            font-size: 12px;
            color: #4E5969;
            line-height: 22px;
            margin-right: 10px;  
        }
        .filterItem + .filterItem{
            margin-left: 10px;
        }
    }
    .questionTip{
        margin-left: 5px;
    }
}