.page-search {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 960px;
  min-height: calc(100vh - 60px);
  margin: 0 auto;
  .ant-empty-image {
    display: none;
  }
  &_input {
    height: 60px;
    border: 4px solid #333;
    font-size: 16px;
    font-weight: 600;
    font-family: fantasy
  }
  &_btn {
    height: 60px!important;
    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
}