.count {
  background-color: #fff;
  max-width: 1280px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  margin: 0px auto;

  :global {
    .count-item:hover {
      height: 272px;
      background: url("../img/count-bg.png") !important;
      background-size: 150% !important;
      background-position: -100px !important;
      .count-box {
        height: 110px;
        background: rgba(255,255,255,0.5201);
      }
    }
    .count-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 33.33%;
      height: 210px;
      border-radius: 4px;
      > img {
        width: 48px;
        height: 48px;
        margin: 0 0 0 90px;
      }
      >.title {
        margin: 0px 0 24px 90px;
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: 700;
        color: #252B3A;
      }
      .count-box {
        box-sizing: border-box;
        padding: 0 90px;
        display: flex;
        justify-content: space-between;
      }
      .count-box .count-title:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: sub;
        background: url("../img/icon-dy.png");
        background-size: cover;
      }
      .count-box .count:first-child .count-title:before {
        background: url("../img/icon-ks.png");
        background-size: cover;
      }
      .count {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .count-title {
          margin-bottom: 10px;
          font-size: 16px;
          color: #464646;
        }
        .count-num {
          display: flex;
          align-items: center;
          height: 28px;
          font-size: 23px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: 700;
          color: #252B3A;
          line-height: 28px;

          .unit {
            margin-left: 4px;
            font-size: 16px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}