.footerWrapper {
  background: #F7F7F7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-family: Helvetica;
  color: #66666C;

  :global {
    .footer-container {
      display: flex;
      justify-content: space-between;
      width: 1280px;
      padding: 60px 40px 72px 40px;
    }

    .tree-box {
      display: flex;
      flex-wrap: wrap;
      min-width: 908px;
      max-width: calc(100% - 350px);

      >div {
        flex-shrink: 0;
        width: 160px;

        >div {
          &:last-child {
            .child-node {
              margin-bottom: 0 !important;
            }
          }
        }
      }

      .parent-node {
        font-weight: bold;
        color: #303033;
        margin-bottom: 17px;

        a {
          color: #303033;
          font-family: Helvetica;
        }
      }

      .child-node {
        margin-bottom: 16px;

        a {
          margin-bottom: 16px;
          font-size: 14px;
          font-family: Helvetica;
          color: #66666C;
        }
      }
    }

    .code-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      img {
        width: 80px;
        height: 80px;
      }

      .code-tit {
        margin-top: 12px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

}