.apiWrap {
  min-height: calc(100vh - 540px);
  .headBox {
    .centerTit {
      height: 220px;
      background-color: #E8EDF2;
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        width: 1200px;
        font-size: 42px;
        font-weight: bold;
        color: #303033;
        line-height: 55px;
        letter-spacing: 1px;
      }
    }
  }
  .container {
    max-width: 1440px;
    margin: 0 auto;
  }
  .demoCode {
    width: 100%;
    height: 800px;
  }
}