.wrapperBox {
  height: 76px;
  background: #F7F7F7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #EBEBEB;

  :global {
    .info-box {
      font-size: 12px;
      font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
      font-weight: 400;
      color: #9E9E9E;
      text-align: center;
    }
  }

}