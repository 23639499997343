.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 0 32px;
  background: #fff;
  z-index: 99;

  :global {
    .headerWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1920px;
      margin: 0px auto;
    }

    .headerWrapper-left {
      height: 60px;
      display: flex;
      align-items: center;

      .logo {
        height: 28px;

        img {
          width: 186px;
        }
      }

      .menu-box {
        padding: 0 20px;
        width: 900px;

        .ant-menu {
          a {
            font-size: 14px;
            color: #303033;
          }

          >.ant-menu-item-selected a {
            color: #DA2428;
          }

        }

        .ant-menu-horizontal {
          border: none;
        }

        .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal {
          .anticon.anticon-down {
            vertical-align: middle;
            transition: transform .3s;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.85);
          }
        }

        .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-open {
          .anticon.anticon-down {
            transform: rotate(180deg);
          }
        }

      }
    }
  }

}

:global {
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light.ant-menu-submenu-placement-bottomLeft {
    .anticon {
      display: none;
    }
  }

  .ant-menu-item[is_frame="1"] {
    &:after {
      display: none;
    }

    &:hover {
      &:after {
        display: inline-block;
      }
    }
  }
}