.importDataTrackingWrap {
  :global {
    .head-box {
      .center-tit {
        height: 220px;
        background-color: #E8EDF2;
        display: flex;
        justify-content: center;
        align-items: center;

        .title {
          width: 1200px;
          font-size: 42px;
          font-weight: bold;
          color: #303033;
          line-height: 55px;
          letter-spacing: 1px;
        }
      }
    }


    .table_wrap {
      max-width: 1440px;

      .total-box {
        font-size: 14px;
        color: #999;
        height: 40px;
        line-height: 40px;

        span {
          color: #666;
          font-weight: bold;
        }
      }

      margin: 0 auto;

      .redWord {
        color: red;
        font-weight: bold;
      }

      .gray {
        color: #999;
      }

      .line-3 {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        word-break: break-all;
      }
    }
  }
}