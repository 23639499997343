.resourceTracking {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;

  :global {
    .search-head {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 85px;

      .tit {
        font-size: 14px;
        color: #4E5969;
      }

      .ant-input {
        width: 597px;
        margin-left: 12px;
        height: 42px;
        background-color: #f7f7f7;
      }

      .ant-input:focus,
      .ant-input-focused {
        background-color: #fff;
      }

      .ant-btn {
        width: 87px;
        height: 42px;
      }

    }

    .time-line-box {
      min-height: 600px;
      max-width: 1240px;
      margin: 0 auto;

      >ul {
        margin-left: -660px;
        margin-top: 40px;
      }

      .ant-timeline-item-tail {
        border-left: 1px solid #E6E6E6;
      }
    }
  }
}