.text-link {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  color: #3370ff;
  cursor: pointer;
}
.text-bold {
  font-weight: 600;
}
.text-strikethrough {
  text-decoration: line-through
}
.text-underline {
  text-decoration: underline;
}
.text-italic{
  font-style: italic;
}
.text-inline-code {
  font-family: "Source Code Pro",Menlo,Monaco,Consolas,"Liberation Mono","Courier New","Microsoft Yahei";
  padding: 1px 3px;
  background-color: #f5f6f7;
  border: 1px solid #dee0e3;
  border-color: #dee0e3;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.text-color {
  &-1 { color: #d83931; }
  &-2 { color: #de7802; }
  &-3 { color: #dc9b04; }
  &-4 { color: #2ea121; }
  &-5 { color: #245bdb; }
  &-6 { color: #6425d0; }
  &-7 { color: #646a73; }
}
.border-color {
  &-1 { border-color: #d83931; }
  &-2 { border-color: #de7802; }
  &-3 { border-color: #dc9b04; }
  &-4 { border-color: #2ea121; }
  &-5 { border-color: #245bdb; }
  &-6 { border-color: #6425d0; }
  &-7 { border-color: #646a73; }
}
.background-color {
  &-1 { background-color: #fdddef; }
  &-2 { background-color: rgb(254 212 164 / 80%); }
  &-3 { background-color: rgb(255 246 122 / 80%); }
  &-4 { background-color: rgb(183 237 177 / 80%); }
  &-5 { background-color: rgb(186 206 253 / 70%); }
  &-6 { background-color: rgb(205 178 250 / 70%); }
  &-7 { background-color: #f2f3f5; }
  &-8 { background-color: #f76964; }
  &-9 { background-color: #ffa53d; }
  &-10 { background-color: #ffe928; }
  &-11 { background-color: #62d256; }
  &-12 { background-color: rgb(78 131 253 / 55%); }
  &-13 { background-color: rgb(147 90 246 / 55%); }
  &-14 { background-color: #bbbfc4; }
  &-15 { background-color: #f2f3f5; }
}