body {

}
.bigDataWrapper {
  :global {
    .cloudera-cdh-wrapper {
      width: 100%;
      background: linear-gradient(180deg, #FFFFFF 0%, #FBFBFB 100%);
    }
    .cloudera-cdh {
      display: flex;
      justify-content: space-between;
      height: 560px;
      width: 100%;
      max-width: 1280px;
      margin: 0px auto;
      padding-top: 88px;
      .left {
        width: 602px;
        height: 252px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #6E7176;
        line-height: 36px;
        > div {
          padding: 16px 0 23px 0;
          font-size: 36px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #060605;
        }
      }
      .right {}
    }
    .head-box {
      background-image: url('./../img/bigDataBanner_bg.png');
      background-size: cover;
      background-position: center;

      .center-tit {
        font-size: 28px;
        font-weight: bold;
        color: #FFFFFF;
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .tab-box {
        display: flex;
        height: 60px;
        justify-content: space-around;
        background: rgba(255, 255, 255, 0.2);

        span {
          align-items: center;
          display: inline-block;
          height: 60px;
          line-height: 60px;
          font-size: 14px;
          color: #FFFFFF;
          cursor: pointer;
        }

        .active {
          position: relative;

          &:after {
            position: absolute;
            content: '';
            display: block;
            width: 84px;
            height: 4px;
            background: #00F9F8;
            border-radius: 4px 4px 4px 4px;
            font-weight: bold;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

  }
}