.tableWrap {
  margin-top: 32px;

  :global {
    .fn-ml4 {
      margin-left: 4px;
    }

    .ant-table-row.disabled {
      cursor: not-allowed;
    }
  }
}