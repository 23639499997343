.wrapperBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 418px;

  :global {
    .link-item-left {
      background-image: url("./img/left-bg.png");
      background-size: cover;
    }
    .link-item-right {
      background-image: url("./img/right-bg.png");
      background-size: cover;
    }
    .link-item-box {
      cursor: pointer;
      padding: 0 0 0 280px;
      width: 580px;
      height: 262px;
      display: flex;
      justify-content: space-between;

      .link-name {
        height: 20px;
        margin-top: 42px;
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 600;
        color: #181818;
        line-height: 20px;
      }

      .link-btn {
        height: 14px;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 600;
        color: #181818;
        line-height: 14px;
        margin-top: 27px;
      }

      &:first-of-type {
        margin-right: 100px;
      }
    }
  }
}