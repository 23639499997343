.container {
  display: flex;
  position: relative;
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    background: #fff;
    z-index: 1;
    position: absolute;
  }
  .baseInfo {
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      color: #1d2129;
      line-height: 28px;
    }
    .tagNameWrap {
      display: flex;
      align-items: center;
      .tagNameInput {
        width: 50%;
        margin-left: 10px;
        margin-right: 20px;
        background: #f2f3f5;
        border: none;
        box-shadow: none;
      }
    }
  }
  .leftWrap {
    width: 273px;
    background: #fff;
    padding: 10px 13px 10px 20px;
    height: calc(100vh - 60px);

    .treeTitle {
      font-weight: bold;
      color: #1d2129;
      line-height: 28px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .searchInput {
      margin: 10px 0;
      border: none;
      background: #f2f3f5;
      &:focus {
        box-shadow: none !important;
      }
      :global {
        input {
          background: #f2f3f5;
        }
      }
    }
    .treeWrap {
      height: calc(100% - 85px);
      overflow-y: auto;
    }
    .treeItem {
      display: flex;
      justify-content: space-between;
      .folder {
        width: 14px;
        height: 12px;
        margin-right: 10px;
      }
      .tagIcon {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: rgb(201, 205, 212);
        position: relative;
        top: -2px;
        left: -8px;
        &.enable {
          background: rgb(0, 180, 42);
        }
        &.disabled {
          background: #f53f3f;
        }
        &.stop {
          background: red;
        }
        &.uncompile {
          background: yellow;
        }
        &.compiling {
          background: orange;
        }
      }
    }
    .dropdown {
      width: 16px;
      height: 16px;
      margin-top: 4px;
      &.insert {
        margin-right: 4px;
        cursor: pointer;
      }
    }
  }
  .rightWrap {
    padding-left: 24px;
    padding-right: 10px;
    width: calc(100% - 273px);
    height: calc(100vh - 60px);
    position: relative;
    &.emptyData {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
    :global {
      .ant-spin-nested-loading {
        height: calc(100vh - 68px);
        overflow: hidden;
      }
    }
    .toolbar {
      display: flex;
      height: 48px;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;
      .breadcrumb {
        font-size: 14px;
        color: #4e5969;
      }
      .buttonWrap {
        display: flex;
        justify-content: space-between;

        :global {
          button + button {
            margin-left: 8px;
          }
        }
      }
    }
    .content {
      background-color: #fff;
      padding: 20px;
      .top {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          line-height: 28px;
          color: #1d2129;
          font-weight: bold;
        }
        .phraseRelationship {
          .label {
            font-size: 12px;
            line-height: 22px;
            color: #1d2129;
            margin-right: 10px;
          }
        }
      }
      .addArea {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        color: #86909c;
        font-size: 14px;
        line-height: 22px;
        .addButton {
          cursor: pointer;
          position: relative;
          &::before {
            content: '';
            width: 219px;
            height: 1px;
            background: #e5e6eb;
            position: absolute;
            left: -250px;
            top: 10px;
          }
          &::after {
            content: '';
            width: 219px;
            height: 1px;
            background: #e5e6eb;
            position: absolute;
            left: 110px;
            top: 10px;
          }
        }
        .addIcon {
          margin-right: 9px;
        }
      }
    }
    .matchArea {
      margin-top: 20px;
      background-color: #fff;
      padding: 20px;
      .title {
        font-size: 16px;
        color: #1d2129;
        line-height: 28px;
        font-weight: bold;
      }
    }
  }
  .filterArea {
    padding: 0 20px 20px;
    background: #fff;
  }
  .rightContent {
    height: calc(100% - 130px);
    overflow-y: auto;
  }
  .bottomWrap {
    display: flex;
    justify-content: flex-end;
    height: 80px;
    align-items: center;
    padding-right: 27px;
    background: #fff;
  }
}

.compileBg {
  cursor: pointer;
  position: fixed;
  top: 151px;
  right: -50px;
  background-size: 100% 100%;
  width: 180px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 400;
  font-size: 16px;
  color: #d94b4b;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
