.recognition {
  width: 472px;
  padding: 20px;
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #303033;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px dashed #e7e7e7;
    span {
      margin-left: 10px;
      width: 44px;
      height: 20px;
      line-height: 20px;
      background: #fff0ec;
      border-radius: 4px 4px 4px 4px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #fc8f66;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
  }
  .content {
    display: flex;
    margin-top: 16px;
    .contentTag {
      width: 63px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: #f2f3f5;
      border-radius: 2px 2px 2px 2px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #1d2129;
      font-style: normal;
      text-transform: none;
      margin-right: 5px;
    }
    .contentText {
      flex: 1;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
}
