.smMenuWrap {
  box-sizing: border-box;
  height: 100%;
  padding: 10px 0;
  :global {}
}
.smMenu {
  box-sizing: border-box;
  width: 160px;
  padding: 6px;
  min-height: 100%;
  max-height: 100%;
  margin: 0 4px;
  background: #F5F6F7;
  border-radius: 15px;
  font-size: 13px;
  line-height: 16px;
  :global {
    a {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ant-menu-sub {
      background: #F5F6F7;
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding: 14px 20px 14px 20px;
        font-family: Helvetica;
        line-height: 16px;
        border-radius: 10px;
        width: 100%;
        margin-left: 0px;
      }
    }
    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        background: #FFE6E6;
        border-radius: 10px;
      }
    }
    .ant-menu-item {
      display: flex;
      width: 100%;
      padding: 12px 20px 12px 20px!important;
      margin: 1px 0 !important;
    }
    .ant-menu-title-content {
      width: 100%;
    }
    .ant-menu-item-selected {
      background: #FFE6E6;
      border-radius: 10px;
    }
    .ant-menu-item-selected::after {
      display: none;
    }
    .ant-menu-title-content {
      font-size: 13px;
      line-height: 16px;
    }
  }
}