.pageHeadTitle {
  :global {
    .center-tit {
      height: 220px;
      background-color: #E8EDF2;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .title {
        padding-left: 32px;
        font-size: 42px;
        font-weight: bold;
        color: #303033;
        line-height: 55px;
        letter-spacing: 1px;
      }
    }
  }

}