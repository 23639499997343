.dashBoardWrapper {

  :global {
    .head-box {
      height: 380px;
      position: relative;

      //.center-tit {
      //  height: 325px;
      //  background-color: #E8EDF2;
      //  display: flex;
      //  justify-content: center;
      //  align-items: center;
      //
      //  .title {
      //    width: 1200px;
      //    font-size: 42px;
      //    font-weight: bold;
      //    color: #303033;
      //    line-height: 55px;
      //    letter-spacing: 1px;
      //  }
      //}

      .tab-box {
        min-width: 1280px;
        max-width: 1360px;
        display: flex;
        justify-content: center;
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        .tab-box-center {
          width: 1200px;
          height: 108px;
          background: #FFFFFF;
          box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.08);
          display: flex;

          a {
            &:nth-child(2) {
              .link-item {
                border-left: 1px solid #EBEBEB;
                border-right: 1px solid #EBEBEB;
              }
            }
          }

          .link-item {
            width: 400px;
            height: 108px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #303033;


            .itemName {
              margin-top: 14px;
            }
          }

          .active {
            position: relative;

            &:after {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              content: '';
              display: block;
              width: 316px;
              height: 2px;
              background: #DA2428;
              border-radius: 3px;
            }

            .itemName {
              font-weight: bold;
            }


          }
        }

      }
    }

    .routes-wrapper {
      min-height: 600px;
    }
  }
}